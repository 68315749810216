<template>
  <a-spin :spinning="spinning">
    <a-tabs tab-position="left" style="background: #fff">
      <a-tab-pane key="1" tab="发放管理">
        <!-- <react-table
          :table-data="tableData"
          :total-items="totalItems"
          :showTotal="showTotalFun"
          :columns="columns"
          :scroll="{ x: 2530 }"
          :actions-type="actionsType"
          :hide-row-selection="true"
          :is-full="true"
          :current-page="pageNumber"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitSortData="updateSortData"
          @emitShowSizeChange="updateShowSizeChange"
        > -->
        <ele-table
          :columns="columns"
          :table-data="tableData"
          :total-items="totalItems"
          :is-full="true"
          :actionsType="actionsType"
          :current-page="pageNumber"
          :hide-row-selection="true"
          @emitRefreshData="clearFilterAndRefresh"
          @emitSelectChange="updateSelectChange"
          @emitOnPageChange="updatePageChange"
          @emitShowSizeChange="updateShowSizeChange"
        >
          <a-row :gutter="8">
            <a-col class="gutter-row" :span="4">
              <a-input
                v-model.trim="SearchDto.BatchNo"
                :placeholder="l('BatchNo')"
                style="width: 100%"
                allowClear
              ></a-input>
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-month-picker
                v-model="SearchDto.BatchMonth"
                valueFormat="YYYY-MM"
                :placeholder="l('SalaryMonth')"
                style="width: 100%"
              />
            </a-col>
            <a-col class="gutter-row" :span="5">
              <a-select
                v-model="SearchDto.Status"
                style="width: 100%"
                :placeholder="l('PleaseSelectStatus')"
                allowClear
              >
                <a-select-option
                  v-for="item in SalaryStatus"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="5">
              <a-select
                v-model="SearchDto.CustomerId"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :placeholder="l('PleaseSelectCustomer')"
                allowClear
                @blur="testBlur"
              >
                <a-select-option
                  v-for="item in Customers"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="4">
              <a-button-group>
                <a-button
                  type="primary"
                  @click="search"
                  v-if="isGranted('Pages.LGModul.Salary.Query')"
                >
                  {{ l("Search") }}
                </a-button>
                <a-button @click="clearAndGetData"> {{ l("Reset") }}</a-button>
                <a-button @click="showSearchDrawer">高级搜索</a-button>
              </a-button-group>
            </a-col>
            <a-col class="gutter-row" :span="2" style="text-align: right">
              <a-button
              v-if="isGranted('Pages.LGModul.Salary.Export')"
                @click="exportExcel"
              >
                导出Excel
              </a-button>
            </a-col>
          </a-row>
          <!-- <a-row :gutter="8" style="margin-top: 10px">
            <a-col class="gutter-row" :span="5">
              <a-select
                v-model="SearchDto.PayCompanyId"
                show-search
                :filter-option="filterOption"
                style="width: 100%"
                :placeholder="l('PlaceSelectPayCompany')"
                allowClear
              >
                <a-select-option
                  v-for="item in PayCompanys"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-col>
            <a-col class="gutter-row" :span="5">
              <a-range-picker
                v-model="SearchDto.BatchTime"
                :show-time="{ format: 'HH:mm' }"
                :placeholder="['开始时间', '结束时间']"
                valueFormat="YYYY-MM-DD HH:mm"
                style="width: 100%"
                @change="onChange1"
                @ok="onOk"
              />
            </a-col>
            <a-col class="gutter-row" :span="5">
              <a-input
                v-model="SearchDto.projectName"
                placeholder="请选择项目"
                @click="selectProjectModal"
              >
              </a-input>
              <a-input v-model="SearchDto.projectId" type="hidden"> </a-input>
            </a-col>
            <a-col class="gutter-row" :span="5">
              <a-input-number
                style="width: 100%"
                placeholder="发放总金额"
                v-model="SearchDto.billAmount"
                :min="0"
                :step="0.01"
              />
            </a-col>
          </a-row> -->
        </ele-table>
      </a-tab-pane>
      <a-tab-pane key="2" tab="发放失败">
        <settlementDetailModule :defaultStatus="2"></settlementDetailModule>
      </a-tab-pane>
    </a-tabs>
    <a-drawer
      title="搜索"
      :width="750"
      :visible="searchDrawerVisible"
      :body-style="{ paddingBottom: '80px' }"
      @close="onSearchDrawerClose"
    >
      <a-form :form="SearchDto" layout="vertical" hide-required-mark>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="关键字">
              <a-input
                v-model.trim="SearchDto.BatchNo"
                :placeholder="l('BatchNo')"
                style="width: 100%"
                allowClear
              ></a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发放月份">
              <a-month-picker
                v-model="SearchDto.BatchMonth"
                valueFormat="YYYY-MM"
                :placeholder="l('SalaryMonth')"
                style="width: 100%"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="发放时间">
              <a-range-picker
                v-model="SearchDto.BatchTime"
                :show-time="{ format: 'HH:mm' }"
                :placeholder="['开始时间', '结束时间']"
                valueFormat="YYYY-MM-DD HH:mm"
                style="width: 100%"
                @change="onChange1"
                @ok="onOk"
              />
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发放状态">
              <a-select
                v-model="SearchDto.Status"
                style="width: 100%"
                :placeholder="l('PleaseSelectStatus')"
                allowClear
              >
                <a-select-option
                  v-for="item in SalaryStatus"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="客户">
              <a-select
                v-model="SearchDto.CustomerId"
                style="width: 100%"
                show-search
                :filter-option="filterOption"
                :placeholder="l('PleaseSelectCustomer')"
                allowClear
                @blur="testBlur"
              >
                <a-select-option
                  v-for="item in Customers"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发放公司">
              <a-select
                v-model="SearchDto.PayCompanyId"
                show-search
                :filter-option="filterOption"
                style="width: 100%"
                :placeholder="l('PlaceSelectPayCompany')"
                allowClear
              >
                <a-select-option
                  v-for="item in PayCompanys"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="项目">
              <a-input
                v-model="SearchDto.projectName"
                placeholder="请选择项目"
                @click="selectProjectModal"
              >
              </a-input>
              <a-input v-model="SearchDto.projectId" type="hidden"> </a-input>
            </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label="发放总金额">
              <a-input-number
                style="width: 100%"
                placeholder="发放总金额"
                v-model="SearchDto.billAmount"
                :min="0"
                :step="0.01"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="是否提现记录">
              <a-select
                v-model="SearchDto.isWithdraw"
                style="width: 100%"
                :placeholder="l('是否提现记录')"
                allowClear
              >
                <a-select-option value="true">提现记录</a-select-option>
                <a-select-option value="false">非提现记录</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <!-- <a-col :span="12">
            <a-form-item label="提现订单号">
              <a-input
                v-model="SearchDto.withdrawalOrderNo"
                placeholder="提现订单号"
              >
              </a-input>
            </a-form-item>
          </a-col> -->
        </a-row>
        <div>
          <a-button :style="{ marginRight: '8px' }" @click="clearAndGetData">
            <a-icon type="reload" />
            <span>重置</span>
          </a-button>
          <a-button type="primary" @click="searchData">
            <a-icon type="search" />
            <span>搜索</span>
          </a-button>
        </div>
      </a-form>
    </a-drawer>
    <a-modal
      :title="l('HintInformation')"
      :visible="showSettlementInfo"
      @cancel="hideSettlementInfo"
      :width="805"
    >
      <a-row :gutter="24">
        <a-col span="24">
          <a-table
            bordered
            :data-source="settlementErrorResult"
            :pagination="false"
          >
            <a-table-column
              key="realName"
              :title="l('NameSurname')"
              data-index="realName"
              :ellipsis="true"
              width="75px"
            ></a-table-column>
            <a-table-column
              key="phoneNumber"
              :title="l('ProjectUserPhone')"
              data-index="phoneNumber"
              :ellipsis="true"
              width="150px"
            ></a-table-column>
            <a-table-column
              key="idCard"
              :title="l('ProjectUserIdCard')"
              data-index="idCard"
              :ellipsis="true"
              width="190px"
            ></a-table-column>
            <a-table-column
              key="thisTimeAmount"
              :title="l('SettlementAmount')"
              data-index="thisTimeAmount"
              :ellipsis="true"
              width="90px"
            ></a-table-column>
            <a-table-column
              key="remarks"
              :title="l('MessageError')"
              data-index="remarks"
              :ellipsis="true"
              width="250px"
            ></a-table-column>
          </a-table>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button key="back" @click="hideSettlementInfo">{{
          l("Close")
        }}</a-button>
      </template>
    </a-modal>

    <!--�ύ����-->
    <a-modal
      :title="l('BatchSend')"
      :closable="false"
      :visible="showBatchSalary"
      @cancel="hideBatchSalary"
    >
      <a-row :gutter="24">
        <a-col span="6">
          <label>{{ l("发放总金额") }}:</label>
        </a-col>
        <a-col span="18"
          ><span style="font-weight: bold">{{ salaryTotal }}</span> 元</a-col
        >
      </a-row>
      <a-row :gutter="24" style="margin-top: 10px">
        <a-col span="6">
          <label><i style="color: red">*</i>{{ l("InvoiceTitle") }}:</label>
        </a-col>
        <a-col span="18">
          <a-select
            placeholder="请选择"
            v-model="SalaryInvoiceTitle"
            style="width: 100%"
            allow-clear
            :filter-option="invoiceTitleFilterOption"
            show-search
          >
            <a-select-option
              v-for="item in InvoiceTitles"
              :key="item.value"
              :value="item.value"
              >{{ item.label }}</a-select-option
            >
          </a-select>
        </a-col>
      </a-row>
      <a-row :gutter="24" style="margin-top: 10px">
        <a-col span="6">
          <label><i style="color: red">*</i>{{ l("所属月份") }}:</label>
        </a-col>
        <a-col class="gutter-row" :span="18">
          <a-month-picker
            v-model="grantMouth"
            valueFormat="YYYY-MM"
            :placeholder="l('SalaryMonth')"
            style="width: 100%"
          />
        </a-col>
      </a-row>
      <a-row :gutter="24" style="margin-top: 10px">
        <a-col span="6">
          <label
            ><i style="color: red">{{ isTenantId ? "" : "*" }}</i
            >{{ l("可认领账单") }}:</label
          >
        </a-col>
        <a-col class="gutter-row" :span="18">
          <a-input
            v-model="billIdsName"
            placeholder="请选择账单"
            @click="selectBillModal"
          >
          </a-input>
        </a-col>
      </a-row>
      <a-row :gutter="24" style="margin-top: 10px">
        <a-col span="6">{{ l("ProjectRemark") }}:</a-col>
        <a-col span="18">
          <a-textarea
            v-model="SalaryRemark"
            style="width: 100%"
            :rows="5"
          ></a-textarea>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :disabled="spinning"
          @click="submitSalary()"
          >{{ l("BatchSend") }}</a-button
        >
        <a-button :disabled="spinning" key="back" @click="hideBatchSalary">{{
          l("Close")
        }}</a-button>
      </template>
    </a-modal>
    <!--����-->
    <a-modal
      :title="l('BatchInvilad')"
      :visible="showBatchInvalid"
      @cancel="hideBatchInvalid"
    >
      <a-row :gutter="24">
        <a-col span="6"
          ><span style="color: red">*</span
          >{{ l("BatchInvilad") + l("reason") }}:</a-col
        >
        <a-col span="18">
          <a-textarea
            v-model="SalaryInvalidReason"
            style="width: 100%"
            :rows="5"
          ></a-textarea>
        </a-col>
      </a-row>
      <template slot="footer">
        <a-button
          key="submit"
          type="primary"
          :disabled="spinning"
          @click="submitInvaild()"
          >{{ l("BatchInvilad") }}</a-button
        >
        <a-button key="back" @click="hideBatchInvalid">{{
          l("Close")
        }}</a-button>
      </template>
    </a-modal>
  </a-spin>
</template>
<script>
import { AppComponentBase } from "@/shared/component-base";
import { ModalHelper } from "@/shared/helpers";
import { appSessionService } from "@/shared/abp";
import moment from "moment";
import EleTable from "@/components/ele-table";
import SalaryDetail from "@/components/salary/salarydetail.vue";
import SelectPageList from "@/components/linggong/select-page-list-free-width";
import SalaryErrorDetail from "@/app/financial/detail/list";
import SelectProjectPageList from "@/components/linggong/select-page-list";
import { fileDownloadService } from "@/shared/utils";
export default {
  name: "settlementModule",
  mixins: [AppComponentBase],
  components: { EleTable, settlementDetailModule: SalaryErrorDetail },
  data: function () {
    return {
      spinning: false,
      SearchDto: {
        StartTime: "",
        EndTime: "",
        BatchNo: "",
        BatchMonth: "",
        BatchTime: "",
        Status: undefined,
        CustomerId: undefined,
        PayCompanyId: undefined,
        Sorting: "",
        SkipCount: 0,
        MaxResultCount: 50,
        projectName: "",
        projectId: "",
        billAmount: "",
        isWithdraw: undefined,
        withdrawalOrderNo:"",
      },
      salaryBatchId: "",
      billIds: [],
      billIdsName: "",
      salaryTotal: "",
      totalItems: 0,
      pageNumber: 1,
      pagerange: [0, 1],
      totalPages: 1,
      selectedRowKeys: [],
      selectedRows: [],
      tableData: [],
      SalaryStatus: [
        { label: this.l("全部失败"), value: -1 },
        { label: this.l("WaitForSalary"), value: 0 },
        { label: this.l("WaitForApply"), value: 1 },
        { label: this.l("SalaryInvalid"), value: 2 },
        { label: this.l("SalaryPending"), value: 3 },
        { label: this.l("NotPass"), value: 4 },
        { label: this.l("SalarySended"), value: 5 },
        { label: this.l("部分发放"), value: 6 },
        { label: this.l("流程创建中"), value: 7 },
        { label: this.l("流程处理中"), value: 8 },
        { label: this.l("待匹配"), value: 9 },
      ],
      columns: [
        {
          title: this.l("BatchNo"),
          dataIndex: "batchNo",
          align: "center",
          fixed: "left",
          width: 150,
          scopedSlots: { customRender: "batchNo" },
        },
        {
          title: this.l("Status"),
          dataIndex: "statusName",
          align: "center",
          width: 100,
          fixed: "left",
        },
        {
          title: this.l("ProjectHeaderCustomerName"),
          dataIndex: "customerName",
          //   fixed: "left",
          width: 250,
          ellipsis: true,
          //   align: "center",
        },
        {
          title: this.l("PayCompany"),
          dataIndex: "companyName",
          // fixed: 'left',
          width: 250,
          ellipsis: true,
          //   align: "center",
        },
        {
          title: this.l("InvoiceTitle"),
          dataIndex: "invoiceTitle",
          width: 250,
          ellipsis: true,
          //   align: "center",
        },
        {
          title: this.l("项目编码"),
          dataIndex: "projectCode",
          width: 250,
          ellipsis: true,
          //   align: "center",
        },
        {
          title: this.l("项目名称"),
          dataIndex: "projectName",
          width: 250,
          ellipsis: true,
          //   align: "center",
        },
        {
          title: this.l("是否提现"),
          dataIndex: "withdrawal",
          ellipsis: true,
          //   align: "center",
        },
        // {
        //   title: this.l("提现订单号"),
        //   dataIndex: "withdrawalOrderNo",
        //   width: 250,
        //   ellipsis: true,
        //     align: "center",
        // },
        {
          title: this.l("OrderDate"),
          dataIndex: "creationTime",
          align: "center",
          sorter: true,
          width: 200,
          customRender: (text, record, index) => {
            //var html = text ? text.replace("T", " ") : "";
            if (text) return moment(text).format("YYYY-MM-DD HH:mm:ss");
            return "-";
          },
        },
        {
          title: this.l("SalaryTotalAmount"),
          dataIndex: "salaryTotal",
          align: "center",
          width: 150,
        },
        {
          title: this.l("SalaryCount"),
          dataIndex: "salaryCount",
          align: "center",
          width: 150,
        },
        {
          title: this.l("SuccessCount"),
          dataIndex: "success",
          align: "center",
          width: 100,
        },
        {
          title: this.l("FailedCount"),
          dataIndex: "failed",
          align: "center",
          width: 100,
        },
        {
          title: this.l("ProcessingCount"),
          dataIndex: "pending",
          align: "center",
          width: 120,
        },
        {
          title: this.l("ReSalaryCount"),
          dataIndex: "resalary",
          align: "center",
          width: 130,
        },
        {
          title: this.l("已认领账单金额"),
          dataIndex: "sureAmount",
          align: "center",
          width: 130,
        },
        {
          title: this.l("发放失败金额"),
          dataIndex: "errorAmount",
          align: "center",
          width: 130,
        },
        {
          title: this.l("reason"),
          dataIndex: "failedReason",
          align: "center",
          customRender: (text, record, index) => {
            switch (record.status) {
              case 0: //待处理
              case 1: //待审核
              case 3: //发放中
              case 5: //已发放
              case 6: //部分发放
                return "";
              case 2: //已作废
                return record.invalidReason;
              case 4: //已驳回
                return record.rejectReason;
            }
            return record.failedReason;
          },
          ellipsis: true,
          width: 200,
        },
        {
          title: this.l("ProjectRemark"),
          dataIndex: "remark",
          align: "center",
          ellipsis: true,
          width: 200,
        },
        {
          title: this.l("Actions"),
          dataIndex: "actions",
          fixed: "right",
          sorter: false,
          align: "center",
          width: 100,
          scopedSlots: { customRender: "actions" },
        },
      ],
      PayCompanys: [], // { value: "", label: this.l("PlaceSelectPayCompany") }
      Customers: [], //{ value: "", label: this.l("PleaseSelectCustomer") }
      actionsType: [],
      showBatchSalary: false,
      showBatchInvalid: false,
      currentSalaryData: null,
      SalaryInvoiceTitle: undefined,
      SalaryInvalidReason: "",
      SalaryRemark: "",
      InvoiceTitles: [], // { value: "", label: this.l("PleaseSelect") }
      grantMouth: "",
      showSettlementInfo: false,
      settlementErrorResult: [],
      searchDrawerVisible: false,
    };
  },
  computed: {
    isTenantId() {
      if (appSessionService.tenantId) return true;
      else return false;
    },
  },
  methods: {
    searchData() {
      this.searchDrawerVisible = false;
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    showSearchDrawer() {
      this.searchDrawerVisible = true;
    },
    onSearchDrawerClose() {
      this.searchDrawerVisible = false;
    },
    invoiceTitleFilterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    onChange1(value, dateString) {
      this.SearchDto.StartTime = "";
      this.SearchDto.EndTime = "";
      console.log("Selected Time: ", value);
      console.log("Formatted Selected Time: ", dateString);
    },
    onOk(value) {
      console.log("onOk: ", value);
      this.SearchDto.StartTime = value[0];
      this.SearchDto.EndTime = value[1];
    },
    selectBillModal() {
      ModalHelper.create(
        SelectPageList,
        {
          searchPlaceholder: "付方名称/帐单金额(全匹配)",
          selectType: "checkbox", // checkbox radio
          title: "选择可认领账单", // 显示标题
          url: "/api/services/app/Salary/GetSalaryBillPages",
          otherParams: [{ key: "salaryBatchId", value: this.salaryBatchId }],
          columnsData: [
            {
              title: "付方名称",
              dataIndex: "payerNames",
              slots: { title: "customTitle" },
              align: "center",
              ellipsis: true,
              width: 250,
              scopedSlots: { customRender: "payerNames" },
            },
            {
              title: "客户名称",
              dataIndex: "clientName",
              slots: { title: "customTitle" },
              align: "center",
              ellipsis: true,
              width: 250,
              scopedSlots: { customRender: "clientName" },
            },
            {
              title: "项目名称",
              dataIndex: "projectName",
              slots: { title: "customTitle" },
              align: "center",
              ellipsis: true,
              scopedSlots: { customRender: "projectName" },
            },
            {
              title: "账单金额",
              dataIndex: "billAmount",
              slots: { title: "customTitle" },
              align: "center",
              ellipsis: true,
              scopedSlots: { customRender: "billAmount" },
            },
            {
              title: "实发金额",
              dataIndex: "financePayingAmount",
              slots: { title: "customTitle" },
              align: "center",
              scopedSlots: { customRender: "financePayingAmount" },
            },
            {
              title: "流水已认领金额",
              dataIndex: "sureClaimantAmount",
              slots: { title: "customTitle" },
              align: "center",
              scopedSlots: { customRender: "sureClaimantAmount" },
            },
            {
              title: "账单已使用金额",
              dataIndex: "sureAmount",
              slots: { title: "customTitle" },
              align: "center",
              scopedSlots: { customRender: "sureAmount" },
            },
            {
              title: "账单可认领金额",
              dataIndex: "canSureAmount",
              slots: { title: "customTitle" },
              align: "center",
              scopedSlots: { customRender: "canSureAmount" },
            },
            {
              title: "账单月份",
              dataIndex: "billMonth",
              slots: { title: "customTitle" },
              align: "center",
              scopedSlots: { customRender: "billMonth" },
            },
            {
              title: "账单类型",
              dataIndex: "billType",
              slots: { title: "customTitle" },
              customRender: function (text) {
                if (text === 0) {
                  return "非垫付";
                } else {
                  return "垫付";
                }
              },
              align: "center",
              scopedSlots: { customRender: "billType" },
            },
            {
              title: "项目编码",
              dataIndex: "projectCode",
              slots: { title: "customTitle" },
              align: "center",
              ellipsis: true,
              width: 250,
              scopedSlots: { customRender: "projectCode" },
            },
          ],
        },
        {
          isChange: true,
          width: "1300px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          console.log(data);
          if (data.length) {
            this.billIds = data.map((v) => {
              return v.id;
            });
            this.billIdsName = data
              .map((v) => {
                return v.clientName;
              })
              .join(",");
          }
          // this.searchFormInline.ProjectId = data.id;
          // this.searchFormInline.ProjectName = data.name;
          // this.getTasksByProjectId(data.id);
        }
      });
    },
    /**
     * ������
     * @param input
     * @param option
     */
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    /**
     * ��ʼ��������*/
    initActionType() {
      let obj = {
        type: "sign-manage",
        isShow: true,
        fns: {
          dropList: [
            {
              granted: this.isGranted("Pages.LGModule.Salary.Send"),
              name: this.l("BatchNo").substring(0, 2),
              icon: "plus",
              fn: (data) => {
                if (data.status != 0) {
                  abp.message.warn(this.l("SalarySendError"));
                  return false;
                }
                //����
                this.getInvoiceTitle(data.customerName);

                this.SearchDto.BatchNo = undefined;
                this.grantMouth = "";
                this.SalaryRemark = "";
                this.SalaryInvoiceTitle = undefined;

                this.SearchDto.StartTime = "";
                this.SearchDto.EndTime = "";

                this.showBatchSalary = true;
                this.salaryBatchId = data.id;
                this.billIds = [];
                this.billIdsName = "";
                this.salaryTotal = data.salaryTotal;
                this.currentSalaryData = data;
              },
            },
            {
              granted: this.isGranted("Pages.LGModule.Salary.Invalid"),
              name: this.l("SalaryInvalid").substring(1, 3),
              icon: "close",
              fn: (data) => {
                if (data.status != 0) {
                  abp.message.warn(this.l("SalaryInvalidError"));
                  return false;
                }
                //����
                this.currentSalaryData = data;
                this.showBatchInvalid = true;
              },
            },
            {
              granted: this.isGranted("Pages.LGModule.Salary.SalaryDetail"),
              name: this.l("SalaryDetail"),
              icon: "ordered-list",
              fn: (data) => {
                ModalHelper.create(
                  SalaryDetail,
                  { id: data.id, showrs: 1 },
                  {
                    isChange: true,
                    width: "1200px",
                  }
                );
              },
            },
          ],
        },
      };
      this.actionsType = obj;
    },
    /**
     * ��ȡ���з��Ź�˾
     * */
    getAllPayCompany() {
      this.spinning = true;
      this.$api
        .request({
          // url: this.$apiUrl + "/api/services/app/Organization/GetAllSelectList",
          url: this.$apiUrl + "/api/services/app/Organization/GetSelectList",
          method: "GET",
        })
        .then((res) => {
          this.PayCompanys.splice(0, this.PayCompanys.length);
          if (res && res.data) {
            this.PayCompanys = res.data.map((item) => {
              return {
                value: item.value,
                label: item.text,
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    /**
     * ��ȡ�ͻ�
     * */
    getAllCutomer() {
      let options = {
        url: this.$apiUrl + "/api/services/app/Customer/GetCustomerOption",
        method: "GET",
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            this.Customers.splice(1, this.Customers.length - 1);
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                this.Customers.push(res.data[i]);
                // console.log(this.Customers)
              }
            }
          }
        })
        .catch((e) => {
          console.error(e);
        });
    },
    /**
     * ��ȡ�ͻ���Ʊ̧ͷ
     * @param name �ͻ�����
     */
    getInvoiceTitle(name) {
      // this.InvoiceTitles.splice(1, this.InvoiceTitles.length);
      this.InvoiceTitles = [];
      let customers = this.Customers.filter((p) => p.label == name);
      if (customers && customers.length > 0) {
        this.spinning = true;
        let customerId = customers[0].value;
        let options = {
          url:
            this.$apiUrl +
            "/api/services/app/Customer/GetInvoiceTitleSelectList",
          method: "GET",
          params: { customerId: customerId },
        };
        this.$api
          .request(options)
          .then((res) => {
            if (res.status == 200) {
              for (let i = 0; i < res.data.length; i++) {
                let item = res.data[i];
                this.InvoiceTitles.push({
                  value: item.value,
                  label: item.text,
                });
              }
            }
          })
          .catch((e) => {
            console.error(e);
          })
          .finally(() => {
            this.spinning = false;
          });
      }
    },
    /**
     * �ύ����
     */
    submitSalary() {
      // this.spinning = true;
      if (this.SalaryInvoiceTitle == "" || !this.SalaryInvoiceTitle) {
        abp.message.warn(this.l("PleaseSelectInvoiceTitle"));
        // this.spinning = false;
        return;
      }
      if (this.grantMouth == "") {
        abp.message.warn(this.l("请选择所属月份"));
        // this.spinning = false;
        return;
      }
      if (!this.isTenantId && !this.billIds.length) {
        abp.message.warn(this.l("请选择可认领账单"));
        // this.spinning = false;
        return;
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/SendSalaryApply",
        method: "POST",
        data: {
          salaryBatchId: this.currentSalaryData.id,
          customerBaseInvoiceId: this.SalaryInvoiceTitle,
          remark: this.SalaryRemark,
          grantMouth: this.grantMouth,
          billIds: this.billIds,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          console.log(res);
          let data = res.data;
          if (data && data.length > 0) {
            this.showSettlementInfo = true;
            this.settlementErrorResult.splice(
              0,
              this.settlementErrorResult.length
            );
            this.settlementErrorResult = data;

            this.showBatchSalary = false;
          } else {
            abp.message.success(this.l("SuccessfullyOperation"));
            this.hideBatchSalary();
          }
          this.spinning = false;
          this.getData();
        })
        .catch((e) => {
          abp.message.error(this.l("Error500Desc"));
          console.error(e);
          this.spinning = false;
        })
        .finally(() => {
          // this.spinning = false;
        });
    },
    /**
     * ����
     */
    submitInvaild() {
      this.spinning = true;
      if (this.SalaryInvalidReason == "") {
        abp.message.warn(this.l("请填写作废原因"));
        this.spinning = false;
        return false;
      }
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/SetSalaryInvalid",
        method: "POST",
        data: {
          salaryBatchId: this.currentSalaryData.id,
          reason: this.SalaryInvalidReason,
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            abp.message.success(this.l("SuccessfullyOperation"));
            this.getData();
            this.hideBatchInvalid();
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    search() {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.getData();
    },
    /**
     * ��ȡ���� */
    getData() {
      if (this.SearchDto.ProjectId == "") {
        return false;
      }
      this.spinning = true;
      let options = {
        url: this.$apiUrl + "/api/services/app/Salary/GetSalaryPaged",
        method: "GET",
        params: {
          startTime: this.SearchDto.StartTime,
          endTime: this.SearchDto.EndTime,
          batchNo: this.SearchDto.BatchNo,
          yearMonth: this.SearchDto.BatchMonth,
          status:
            this.SearchDto.Status === undefined ? null : this.SearchDto.Status,
          customerId:
            this.SearchDto.CustomerId === undefined
              ? null
              : this.SearchDto.CustomerId,
          companyId:
            this.SearchDto.PayCompanyId === undefined
              ? null
              : this.SearchDto.PayCompanyId,
          sorting: this.SearchDto.sorting,
          skipCount: this.SearchDto.SkipCount,
          maxResultCount: this.SearchDto.MaxResultCount,
          projectIds: this.SearchDto.projectId,
          billAmount: this.SearchDto.billAmount,
          isWithdraw: this.SearchDto.isWithdraw,
          withdrawalOrderNo:this.SearchDto.withdrawalOrderNo
        },
      };
      this.$api
        .request(options)
        .then((res) => {
          if (res.status == 200) {
            let data = res.data;
            // this.totalPages =
            //   (data.totalCount + this.SearchDto.MaxResultCount - 1) /
            //   this.SearchDto.MaxResultCount;
            this.totalPages = Math.ceil(
              data.totalCount / this.SearchDto.MaxResultCount
            );
            this.totalItems = data.totalCount;
            this.pagerange = [
              (this.pageNumber - 1) * this.SearchDto.MaxResultCount + 1,
              this.pageNumber * this.SearchDto.MaxResultCount,
            ];
            this.tableData = data.items.map((item, index) => {
              let counts = item.salaryPayStatusCounts;
              let success = 0,
                failed = 0,
                pending = 0,
                resalary = 0;
              if (counts && counts.length > 0) {
                let sus = counts.filter((it) => it.status == 1);
                success = sus && sus.length > 0 ? sus[0].count : 0;
                let fail = counts.filter((it) => it.status == 2);
                failed = fail && fail.length > 0 ? fail[0].count : 0;
                let re = counts.filter((it) => it.status == 3);
                resalary = re && re.length > 0 ? re[0].count : 0;
                let pe = counts.filter((it) => it.status == 5);
                // pending = item.salaryCount - success - failed;
                pending = pe && pe.length > 0 ? pe[0].count : 0;
              }
              return {
                ...item,
                success: success,
                failed: failed,
                pending: pending,
                resalary: resalary,
                creationTime: moment(item.creationTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                failedReason: this.initFailedReason(item, index),
              };
            });
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.spinning = false;
        });
    },
    clearFilterAndRefresh() {
      this.SearchDto.SkipCount = 0;
      this.SearchDto.sorting = "";
      this.getData();
    },
    // 排序更新data
    updateSortData(newV) {
      let { columnKey, order } = newV;
      this.SearchDto.sorting = order ? `${columnKey} ${order}` : "";
      this.getData();
    },
    // ��ҳ�¼�
    showTotalFun() {
      return this.l(
        "GridFooterDisplayText",
        this.pageNumber,
        this.totalPages,
        this.totalItems,
        this.pagerange[0],
        this.pagerange[1]
      );
    },
    updateShowSizeChange(newV) {
      let { current, size } = newV;
      this.showSizeChange(current, size);
    },
    showSizeChange(current, size) {
      this.pageNumber = 1;
      this.SearchDto.SkipCount = 0;
      this.SearchDto.MaxResultCount = size;
      this.getData();
    },
    // tableѡ���¼�
    updateSelectChange(newV) {
      let { selectedRowKeys, selectedRows } = newV;
      this.onSelectChange(selectedRowKeys, selectedRows);
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    // ��ҳ
    updatePageChange(newV) {
      let { page, pageSize, skipCount } = newV;
      this.onChange(page, skipCount);
    },
    onChange(page, pageSize) {
      this.pageNumber = page;
      this.SearchDto.SkipCount = (page - 1) * pageSize;
      this.SearchDto.MaxResultCount = pageSize;
      this.getData();
    },
    /**
     * ���ط��Ŵ���*/
    hideBatchSalary() {
      this.showBatchSalary = false;
    },
    hideBatchInvalid() {
      this.showBatchInvalid = false;
    },
    clearAndGetData() {
      this.searchDrawerVisible = false;
      this.SearchDto.StartTime = "";
      this.SearchDto.EndTime = "";
      this.SearchDto.BatchNo = "";
      this.SearchDto.BatchMonth = "";
      this.SearchDto.BatchTime = "";
      this.SearchDto.Status = undefined;
      this.SearchDto.CustomerId = undefined;
      this.SearchDto.PayCompanyId = undefined;
      this.SearchDto.projectId = "";
      this.SearchDto.projectName = "";
      this.SearchDto.billAmount = "";
      this.SearchDto.isWithdraw = undefined;
      this.SearchDto.withdrawalOrderNo="";
      this.getData();
    },
    testBlur(v) {
      console.log(v);
    },
    hideSettlementInfo() {
      this.showSettlementInfo = false;
    },
    initCurMonth() {
      let d = new Date();
      let y = d.getFullYear();
      let m = d.getMonth() + 1;
      let _m = m < 10 ? `0${m}` : m;
      this.SearchDto.BatchMonth = `${y}-${_m}`;
    },
    initFailedReason: (record, index) => {
      switch (record.status) {
        case 0: //待处理
        case 1: //待审核
        case 3: //发放中
        case 5: //已发放
        case 6: //部分发放
          return "";
        case 2: //已作废
          return record.invalidReason;
        case 4: //已驳回
          return record.rejectReason;
      }
      return record.failedReason;
    },
    selectProjectModal() {
      ModalHelper.create(
        SelectProjectPageList,
        {
          selectType: "checkbox", // checkbox radio
          title: "选择项目", // 显示标题
          url: "/api/services/app/Project/GetSelectPaged", // 数据接口URL
          searchPlaceholder: "项目名称/项目代码",
          columnsData: [
            {
              title: "项目名称",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "项目代码",
              dataIndex: "description",
              key: "description",
            },
          ],
        },
        {
          isChange: true,
          width: "860px",
          heigth: "650px",
        }
      ).subscribe((res) => {
        const { success, data } = res;
        if (success) {
          let projectIds = "";
          let projectNames = "";
          for (let i = 0; i < data.length; i += 1) {
            const item = data[i];
            if (i === data.length - 1) {
              projectIds += item.id;
              projectNames += item.name;
              continue;
            }
            projectIds += item.id + ",";
            projectNames += item.name + ",";
          }
          this.SearchDto.projectId = projectIds;
          this.SearchDto.projectName = projectNames;
        }
      });
    },
    exportExcel() {
      let data = {
        startTime: this.SearchDto.StartTime,
        endTime: this.SearchDto.EndTime,
        batchNo: this.SearchDto.BatchNo,
        yearMonth: this.SearchDto.BatchMonth,
        status:
          this.SearchDto.Status === undefined ? null : this.SearchDto.Status,
        customerId:
          this.SearchDto.CustomerId === undefined
            ? null
            : this.SearchDto.CustomerId,
        companyId:
          this.SearchDto.PayCompanyId === undefined
            ? null
            : this.SearchDto.PayCompanyId,
        sorting: this.SearchDto.sorting,
        skipCount: this.SearchDto.SkipCount,
        maxResultCount: this.SearchDto.MaxResultCount,
        projectIds: this.SearchDto.projectId,
        billAmount: this.SearchDto.billAmount,
        isWithdraw: this.SearchDto.isWithdraw,
        withdrawalOrderNo:this.SearchDto.withdrawalOrderNo
      };
      let newData = {};
      // 循环对象，判断对象中某些属性是否存在，且排除属性值为空格的属性
      for (let key in data) {
        if (
          data[key] &&
          data[key].toString().replace(/(^\s*)|(\s*$)/g, "") !== ""
        ) {
          newData[key] = data[key];
        }
      }
      try {
        this.SendToService({
          url: "/api/services/app/Salary/ExportSalary",
          method: "get",
          params: newData,
          success(res) {
            this.spinning = false;
            fileDownloadService.downloadTempFile(res);
          },
        });
      } catch (error) {
        console.log(error);
        this.spinning = false;
      }
    },
    SendToService(opts) {
      this.spinning = true;
      let options = {
        url: this.$apiUrl + opts.url,
        method: opts.method,
        data: opts.data,
        params: opts.params,
        headers: { "Content-Type": "application/json-patch+json" },
      };
      this.$api
        .request(options)
        .then((res) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(res);
          }
          if (res.status == 200) {
            if (opts.success && typeof opts.success == "function") {
              opts.success(res.data);
            }
          } else {
            abp.message.error(res.statusText);
          }
        })
        .catch((e) => {
          this.spinning = false;
          if (opts.complete && typeof opts.complete == "function") {
            opts.complete(e);
          }
          console.error(e);
        });
    },
  },
  created() {
    if (this.$route.query && this.$route.query.status) {
      this.SearchDto.Status = Number(this.$route.query.status);
    }
    this.initCurMonth();
    this.getAllPayCompany();
    this.getAllCutomer();
    this.initActionType();
  },
  mounted() {
    this.getData();
  },
};
</script>
<style lang="less" scoped>
.btn--container {
  margin-top: 20px;
}

.btn--footer {
  margin-bottom: 20px;
}
</style>
